import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import FormSelectController from 'commons/FormHook/FormSelectController';
import Text from 'commons/Text';
import { OptionsCurrency } from 'configs/Constant';

import useStyles from './styles';

const FormPriceCollector = ({ artworkFormState }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, control, watch, formState } = artworkFormState;

  return (
    <>
      <Text mt={3}>{t('cost_price')}</Text>
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: 'space-between', pt: 1 }}
      >
        <FormInput
          {...register('price')}
          helperText={formState?.errors.price?.message}
          error={!!formState?.errors.price}
          disabled={watch('isAskPriceVisible')}
          wrapFormInputStyle={classes.textFieldController}
        />

        <FormSelectController
          options={OptionsCurrency}
          control={control}
          name="currency"
          valueKey="key"
          textDisplayKey="key"
          selectStyle={classes.select}
          formControlStyle={classes.selectFormControl}
        />
      </Stack>
    </>
  );
};

export default FormPriceCollector;
