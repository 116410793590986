import { Box } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const EditArtworkSection = ({ children, heading, sx }) => {
  const classes = useStyles();

  return (
    <Box
      sx={{ p: 3, ...sx }}
      className={classes.wrapper}
    >
      <Text
        type="xl"
        fontWeightBold
        variant="h2"
      >
        {heading}
      </Text>

      {children}
    </Box>
  );
};

export default EditArtworkSection;
