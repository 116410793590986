import { ListItemButton, ListItemIcon } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const ListMenuItem = ({ text, open, icon, onClick, isActive }) => {
  const classes = useStyles();

  return (
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
      }}
      onClick={onClick}
      className={clsx({
        [classes.menuItemActive]: !!isActive,
      })}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        {icon}
      </ListItemIcon>

      {open && <Text>{text}</Text>}
    </ListItemButton>
  );
};

export default ListMenuItem;
