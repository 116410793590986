import { Box, Container } from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'commons/Breadcrumbs';
import API from 'configs/API';
import Routes from 'utils/Route';
import { axiosGet } from 'utils/apis/axios';

import ArtistProfileColLeft from './components/ArtistProfileColLeft';
import ArtistProfileColRight from './components/ArtistProfileColRight';
import SpecialWorks from './components/SpecialWorks';
import useStyles from './styles';

const ArtistProfile = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);
  const [userProfile, setUserProfile] = useState({});
  const profileContent = useRef('');
  const { id } = useParams();

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.GalleryDetail(user?.id),
      text: t('gallery'),
    },
    {
      text: t('artist_profile'),
      active: true,
    },
  ];

  const downloadPDF = async () => {
    const content = profileContent.current;

    const canvas = await html2canvas(content, { useCORS: true });
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    pdf.addImage(imgData, 'PNG', 10, 10, 190, 0); // 190 is the width of the image, 0 for the height to automatically scale
    pdf.save('profile.pdf');
  };

  useEffect(() => {
    const option = {
      params: {},
    };

    if (id) {
      option.params['user_id'] = id;
    }

    const getProfile = async () => {
      const { success, data } = await axiosGet(API.AUTH.PROFILE_INFO, option);
      if (success) {
        setUserProfile(data);
      }
    };

    getProfile();
  }, [id]);

  return (
    <Box
      className={classes.wrapper}
      ref={profileContent}
    >
      <Container>
        <Box sx={{ pt: 3 }}>
          <Breadcrumbs items={breadCrumbs} />

          <Box
            sx={{ pt: 3 }}
            className={classes.wrapCol}
          >
            <ArtistProfileColLeft
              userProfile={userProfile}
              downloadPDF={downloadPDF}
            />
            <ArtistProfileColRight userProfile={userProfile} />
          </Box>
        </Box>
        <SpecialWorks
          title={t('special_works')}
          userId={id}
        />
      </Container>
    </Box>
  );
};

export default ArtistProfile;
