import { Box } from '@mui/material';

import { TESTID } from 'configs/Constant';

const TabPanel = ({ value, index, children, ...props }) => {
  if (value !== index) {
    return null;
  }

  return (
    <Box
      testid={`${TESTID.TAB_PANEL}_${index}`}
      {...props}
    >
      {children}
    </Box>
  );
};

export default TabPanel;
