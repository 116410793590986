import React from 'react';
import { useSearchParams } from 'react-router-dom';

import TabPanel from 'commons/TabPanel';

import TableArtistTagRequest from '../../Common/TableArtistTagRequest';
import TableShowAllRoleArtist from '../../TableArtistPanel/TableShowAllRoleArtist';

const TableArtist = () => {
  const [searchParams] = useSearchParams();
  const tabValue = Number(searchParams.get('tab') || 0);

  return (
    <>
      <TabPanel
        value={tabValue}
        index={0}
      >
        <TableShowAllRoleArtist />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <TableArtistTagRequest />
      </TabPanel>
    </>
  );
};

export default TableArtist;
