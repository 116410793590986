import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import ManageWrapper from 'commons/ManageWrapper';
import PaginationCustom from 'commons/PaginationCustom';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';
import useRole from 'hooks/useRole';
import Routes from 'utils/Route';

import ManageEditionTable from './components/MangeEditionTable';
import useStyles from './styles';

const ManageEdition = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { isArtist } = useRole();

  const { data: artworks, isPending } = useQuery({
    queryKey: [API.ARTWORK.EDITION, params],
    gcTime: 0,
  });

  const listArtworkEdition = artworks?.results || [];

  const { page, totalPage, onPageChange } = usePagination(artworks?.count, artworks?.page_size);

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      text: t('manage_editions'),
      active: true,
    },
  ];

  if (!isArtist) {
    return (
      <Navigate
        to={Routes.PageNotFound}
        replace
      />
    );
  }

  return (
    <ManageWrapper
      breadCrumbs={breadCrumbs}
      heading={t('manage_editions')}
    >
      <ManageEditionTable
        isPending={isPending}
        listArtworkEdition={listArtworkEdition}
      />

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </ManageWrapper>
  );
};

export default ManageEdition;
