import { Stack } from '@mui/material';
import React from 'react';

import FormInput from 'commons/Form/FormInput';
import FormSelectController from 'commons/FormHook/FormSelectController';
import { OptionsCurrency } from 'configs/Constant';

import EditArtworkPriceVisible from '../EditArtworkPriceVisible';
import useStyles from './styles';

const FormPriceArtist = ({ artworkFormState }) => {
  const classes = useStyles();
  const { register, control, watch, formState } = artworkFormState;

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: 'space-between' }}
      >
        <FormInput
          {...register('price')}
          InputProps={{
            inputProps: {
              type: 'number',
              step: '1',
            },
          }}
          helperText={formState?.errors.price?.message}
          error={!!formState?.errors.price}
          disabled={watch('isAskPriceVisible')}
          wrapFormInputStyle={classes.textFieldController}
        />

        <FormSelectController
          options={OptionsCurrency}
          control={control}
          name="currency"
          valueKey="key"
          textDisplayKey="key"
          selectStyle={classes.select}
          formControlStyle={classes.selectFormControl}
        />
      </Stack>

      <EditArtworkPriceVisible artworkFormState={artworkFormState} />
    </>
  );
};

export default FormPriceArtist;
