import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroup from 'commons/FilterArtwork/CheckBoxGroup';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, ORIENTATION_KEY } from 'configs/FilterArtwork';

const { ORIENTATION } = ARTWORK_FILTER_PARAMS;

const FilterOrientation = () => {
  const { data: orientations = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.ORIENTATION],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedOrientationParams = searchParams.get(ORIENTATION)?.split(',') || [];

  const handleCheckboxChange = (medium) => {
    const isChecked = selectedOrientationParams.find(
      (item) => item === medium[ORIENTATION_KEY.CHECKED]?.toString(),
    );

    searchParams.delete('page');

    if (isChecked) {
      const newSelectOrientationParams = [...selectedOrientationParams].filter(
        (item) => item !== medium[ORIENTATION_KEY.CHECKED]?.toString(),
      );

      if (newSelectOrientationParams.length) {
        searchParams.set(ORIENTATION, newSelectOrientationParams);
        setSearchParams(searchParams);
      } else {
        searchParams.delete(ORIENTATION);
        setSearchParams(searchParams);
      }
      return;
    }

    searchParams.set(ORIENTATION, [...selectedOrientationParams, medium[ORIENTATION_KEY.CHECKED]]);
    setSearchParams(searchParams);
  };

  return (
    <CheckBoxGroup
      listCheckBox={orientations}
      selectedFilters={selectedOrientationParams}
      onCheckboxChange={handleCheckboxChange}
      textDisplayKey={ORIENTATION_KEY.DISPLAY}
      textDisplayViKey={ORIENTATION_KEY.DISPLAY_VI}
      checkedKey={ORIENTATION_KEY.CHECKED}
    />
  );
};

export default FilterOrientation;
