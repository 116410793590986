import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Avatar } from '@mui/material';
import React from 'react';

import FormInput from 'commons/Form/FormInput';
import { STATUS_REQUEST_KEY } from 'configs/Constant';

import useStyles from './style';

const FormInputWithIcons = ({
  selectedUser,
  statusTagRequest,
  textFieldStyle,
  onClickClearIon,
}) => {
  const classes = useStyles();

  return (
    <FormInput
      value={`${selectedUser?.legal_name || ''} ${
        selectedUser?.name ? `[${selectedUser.name}]` : ''
      }`}
      disabled
      labelStyle={classes.formInputLabel}
      wrapFormInputStyle={classes.wrapFormInput}
      textFieldStyle={textFieldStyle}
      iconStartAdornment={
        <Avatar
          className={classes.iconAvatar}
          src={selectedUser?.avatar}
        />
      }
      iconEndAdorment={
        statusTagRequest === STATUS_REQUEST_KEY.REQUEST_APPROVED ? (
          <DoneIcon className={classes.icon} />
        ) : (
          <ClearIcon
            className={classes.icon}
            onClick={onClickClearIon}
          />
        )
      }
    />
  );
};

export default FormInputWithIcons;
