import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapFormInput: {
    marginTop: 15,
    position: 'relative',
  },
  textArea: {
    maxWidth: '100%',
    minWidth: '100%',
    width: '100%',
    minHeight: 150,
    padding: 8,
    fontSize: 12,
    lineHeight: '22px',
    borderRadius: 4,
    border: `1px solid ${Colors.Grey3}`,
  },
  characterCount: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.48px',
    color: Colors.Grey6,
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
}));

export default useStyles;
