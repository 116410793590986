import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

import PrivateRoute from 'commons/PrivateRoute';
import ManageStorage from 'commons/PrivateRoute/ManageStorage';
import PublicRoute from 'commons/PublicRoute';
import useUserAccount from 'hooks/useUserAccount';
import AboutUs from 'pages/AboutUs';
import ArtistProfile from 'pages/ArtistProfile';
import Artists from 'pages/Artists';
import ArtworkDetail from 'pages/ArtworkDetail';
import ChooseAccountType from 'pages/ChooseAccountType';
import CreateCertificate from 'pages/CreateCertificate';
import EditArtistProfile from 'pages/EditArtistProfile';
import EditArtworkRaw from 'pages/EditArtwork';
import EditCertificateRequest from 'pages/EditCertificateRequest';
import Gallery from 'pages/Gallery';
import Home from 'pages/Home';
import Login from 'pages/Login';
import ManageArtwork from 'pages/ManageArtwork';
import ManageCertificate from 'pages/ManageCertificate';
import ManageEdition from 'pages/ManageEdition';
import MyAccount from 'pages/MyAccount';
import PageNotFound from 'pages/PageNotFound';
import RequestCertificate from 'pages/RequestCertificate';
import ReviewCertificateRequest from 'pages/ReviewCertificateRequest';
import ReviewTagRequest from 'pages/ReviewTagRequest';
import UploadArtwork from 'pages/UploadArtwork';
import VerifyInformation from 'pages/VerifyInformation';
import Path from 'utils/Route';
import { setAxiosDefaultAuthToken, setAxiosDefaultLanguage } from 'utils/utils';

const AppRouter = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const language = useSelector((state) => state?.language?.currentLanguage);

  const { getUserAccount } = useUserAccount();

  isLoggedIn && setAxiosDefaultAuthToken(reactLocalStorage.get('token'));

  useEffect(() => {
    const isStaging = process.env.REACT_APP_ENVIRONMENT === 'STAGING';
    if (isStaging) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getUserAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    const currentLanguage = language || 'en';
    setAxiosDefaultLanguage(currentLanguage);
    i18n.changeLanguage(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path={Path.Login}
          exact
          element={<Login />}
        />
        {/* <Route
          path={Path.SignUp}
          exact
          element={<SignUp />}
        /> */}
        <Route
          path={Path.ChooseAccountType}
          exact
          element={<ChooseAccountType />}
        />

        <Route
          path={Path.VerifyInformation}
          exact
          element={<VerifyInformation />}
        />

        <Route element={<PublicRoute />}>
          <Route
            path={Path.Home}
            exact
            element={<Home />}
          />
          <Route
            path={Path.ArtworkDetail()}
            exact
            element={<ArtworkDetail />}
          />
          <Route
            path={Path.Artists}
            exact
            element={<Artists />}
          />
          <Route
            path={Path.GalleryDetail()}
            exact
            element={<Gallery />}
          />
          <Route
            path={Path.AboutUs}
            exact
            element={<AboutUs />}
          />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route
            path={Path.MyAccount}
            exact
            element={<MyAccount />}
          />
          <Route
            path={Path.UploadArtwork}
            exact
            element={<UploadArtwork />}
          />
          <Route
            path={Path.EditArtwork()}
            exact
            element={<EditArtworkRaw />}
          />
          <Route
            path={Path.ArtistProfile()}
            exact
            element={<ArtistProfile />}
          />
          <Route
            path={Path.EditArtistProfile}
            exact
            element={<EditArtistProfile />}
          />
          <Route
            path={Path.RequestCertificateId()}
            exact
            element={<RequestCertificate />}
          />

          <Route
            path={Path.ReviewCertificateRequest()}
            exact
            element={<ReviewCertificateRequest />}
          />
          <Route
            path={Path.ReviewTagRequest()}
            exact
            element={<ReviewTagRequest />}
          />
          <Route
            path={Path.EditCertificateRequest()}
            exact
            element={<EditCertificateRequest />}
          />
          <Route
            path={Path.CreateCertificate()}
            exact
            element={<CreateCertificate />}
          />
          <Route element={<ManageStorage />}>
            <Route
              path={Path.ManageArtwork}
              exact
              element={<ManageArtwork />}
            />
            <Route
              path={Path.ManageEdition}
              exact
              element={<ManageEdition />}
            />
            <Route
              path={Path.ManageCertificate}
              exact
              element={<ManageCertificate />}
            />
          </Route>
        </Route>

        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
