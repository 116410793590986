import { Box, Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { ReactComponent as IconGlobalLine } from 'images/icon-global-line.svg';
import { ReactComponent as IconPrivate } from 'images/icon-private.svg';

import EditArtworkSection from '../EditArtworkSection';
import EditArtworkVisibleOption from './EditArtworkVisibleOption';
import useStyles from './styles';

const EditArtworkVisible = ({ artworkFormState }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { setValue, watch } = artworkFormState;

  const isPublic = watch('isPublic');

  const handlePublicArtwork = () => {
    setValue('isPublic', true);
  };

  const handlePrivateArtwork = () => {
    setValue('isPublic', false);
  };

  return (
    <EditArtworkSection
      sx={{ mt: 2 }}
      heading={t('visibility')}
    >
      <Text
        mt={8}
        type="sm"
      >
        {t('control_how_artwork_is_viewed')}
      </Text>

      <Box sx={{ mt: 1 }}>
        <EditArtworkVisibleOption
          isActive={isPublic}
          onClick={handlePublicArtwork}
          title={t('public')}
          description={t('artwork_details_page_visible_everyone')}
          icon={<IconGlobalLine />}
        />

        <Divider className={classes.divier} />

        <EditArtworkVisibleOption
          isActive={!isPublic}
          onClick={handlePrivateArtwork}
          title={t('private')}
          description={t('only_you_can_see_this_artwork')}
          icon={<IconPrivate />}
        />
      </Box>
    </EditArtworkSection>
  );
};

export default EditArtworkVisible;
