import { Avatar } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const InfoUserTagRequest = ({ user, infoUserStyle, requestDate }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.container, infoUserStyle)}>
      <div className={classes.wrapUserInfo}>
        <Avatar
          className={classes.avatar}
          src={user?.avatar}
        />

        <div>
          <div className={classes.WrapNameArtist}>
            <Text
              className={classes.textNameArtist}
              fontWeightBold
            >
              {user?.legal_name}
            </Text>
            {user?.name && <Text fontWeightBold>{`[${user?.name}]`}</Text>}
          </div>
          <Text className={classes.textId}>{`${t('requested_date')}: ${requestDate}`}</Text>
        </div>
      </div>
    </div>
  );
};

export default InfoUserTagRequest;
