import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './style';

const FormInputDescription = ({
  value,
  onChange,
  wrapFormInputStyle,
  labelStyle,
  textAreaStyle,
  label,
  placeholder,
  maxLength,
  testid,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapFormInput, wrapFormInputStyle)}>
      <Text
        className={labelStyle}
        type="lg"
        fontWeightBold
      >
        {label}
      </Text>
      <textarea
        className={clsx(classes.textArea, textAreaStyle)}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        testid={testid}
      />
      <div className={classes.characterCount}>{`${value.length}/${maxLength}`}</div>
    </div>
  );
};

export default FormInputDescription;
