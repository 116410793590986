import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ArtworkCol from '../../../../../../../../commons/ArtworkCol';
import EditionLocation from '../EditionLocation';
import EditionStatus from '../EditionStatus';
import useStyles from './styles';

const EditionRow = ({
  artwork,
  edition,
  isEvenRow,
  editionIndex,
  editionsSize,
  statusOption,
  locationOption,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { certificate } = artwork || {};
  const { code } = certificate || {};

  const isFirstEdition = editionIndex === 0;
  const isLastEdition = editionIndex === editionsSize - 1;
  const notHasViewMore = isLastEdition && artwork.editions.length <= 3;
  const editionHasCertificate = !!edition?.id_certificate;

  return (
    <>
      <TableRow
        key={edition?.id}
        className={clsx(classes.tableNoBorder, {
          [classes.tableRowBorder]: notHasViewMore,
          [classes.tableEvenRow]: isEvenRow,
        })}
      >
        {isFirstEdition && (
          <TableCell rowSpan={editionsSize}>
            <ArtworkCol
              id={artwork?.id}
              image={artwork?.image}
              title={artwork?.title}
              content={`${t('edition_volume')}: ${artwork?.editions.length}`}
              isLink={true}
              code={code}
            />
          </TableCell>
        )}

        <TableCell>{edition?.edition_number}</TableCell>
        <TableCell>
          <EditionStatus
            editionId={edition?.id}
            editionStatus={edition?.status}
            statusOption={statusOption}
            editionHasCertificate={editionHasCertificate}
          />
        </TableCell>
        <TableCell>{edition?.id_certificate || '--'}</TableCell>
        <TableCell>{edition?.owner_certificate?.name || '--'}</TableCell>
        <TableCell>
          <EditionLocation
            edition={edition}
            locationOption={locationOption}
          />
        </TableCell>
        <TableCell>--</TableCell>
      </TableRow>
    </>
  );
};

export default EditionRow;
