import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroup from 'commons/FilterArtwork/CheckBoxGroup';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, SUBJECT_KEY } from 'configs/FilterArtwork';

const { SUBJECT } = ARTWORK_FILTER_PARAMS;

const FilterSubject = () => {
  const { data: subjects = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.SUBJECT],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedSubjectParams = searchParams.get(SUBJECT)?.split(',') || [];

  const handleCheckboxChange = (subject) => {
    const isChecked = selectedSubjectParams.find(
      (item) => item === subject[SUBJECT_KEY.CHECKED]?.toString(),
    );

    searchParams.delete('page');

    if (isChecked) {
      const newSelectedMediumParams = [...selectedSubjectParams].filter(
        (item) => item !== subject[SUBJECT_KEY.CHECKED]?.toString(),
      );

      if (newSelectedMediumParams.length) {
        searchParams.set(SUBJECT, newSelectedMediumParams);
        setSearchParams(searchParams);
      } else {
        searchParams.delete(SUBJECT);
        setSearchParams(searchParams);
      }
      return;
    }

    searchParams.set(SUBJECT, [...selectedSubjectParams, subject[SUBJECT_KEY.CHECKED]]);
    setSearchParams(searchParams);
  };

  return (
    <CheckBoxGroup
      listCheckBox={subjects}
      selectedFilters={selectedSubjectParams}
      onCheckboxChange={handleCheckboxChange}
      textDisplayKey={SUBJECT_KEY.DISPLAY}
      textDisplayViKey={SUBJECT_KEY.DISPLAY_VI}
      checkedKey={SUBJECT_KEY.CHECKED}
    />
  );
};

export default FilterSubject;
