import { Button } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import DialogArtistName from 'commons/DialogArtistName';
import FormInput from 'commons/Form/FormInput';
import FormCheckBoxController from 'commons/FormHook/FormCheckBoxController';
import FormInputWithIcons from 'commons/FormInputWithIcons';
import Text from 'commons/Text';
import { STATUS_REQUEST_KEY } from 'configs/Constant';

import AutocompleteSearchUserWrapper from './components/AutocompleteSearchUserWrapper';
import DialogCancelTagRequest from './components/DialogCancelTagRequest';
import useStyles from './styles';

const FormUserByRoleCollector = ({
  artworkFormState,
  hasCertificate,
  userFilter,
  handleInputSearchUser,
  handleCheckArtist,
  handleClickPaper,
  handleSelectUser,
  handleSearchAgain,
  handleClickCancelRequest,
  handleClickClearIon,
  handleClickSelectSaveUser,
  dialogCancelTagRequest,
  dialogArtistName,
  isShowCustomPaper,
  titleListUser,
  titleSaveUser,
  roleUser,
  showText = true,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { formState, control, watch, register } = artworkFormState;
  const {
    isShowInputArtistName,
    isShowCheckBoxCustom,
    checkedArtistUnknown,
    selectedUser,
    artistName,
    artistTagRequest,
  } = watch();

  const { request_to, status: statusTagRequest } = artistTagRequest || {};

  return (
    <>
      {selectedUser?.id && (
        <div>
          <FormInputWithIcons
            selectedUser={selectedUser}
            statusTagRequest={statusTagRequest}
            onClickClearIon={handleClickClearIon}
          />
          {statusTagRequest === STATUS_REQUEST_KEY.REQUEST_RECEIVED &&
            selectedUser?.id === request_to?.id &&
            !hasCertificate && (
              <Button
                variant="outlined"
                className={classes.btn}
                onClick={handleClickCancelRequest}
              >
                <Text type="sm">{t('cancel_request')}</Text>
              </Button>
            )}
        </div>
      )}

      {!isShowInputArtistName && !selectedUser?.id && (
        <AutocompleteSearchUserWrapper
          userFilter={userFilter}
          handleInputSearchUser={handleInputSearchUser}
          handleClickPaper={handleClickPaper}
          handleSelectUser={handleSelectUser}
          handleClickSelectSaveUser={handleClickSelectSaveUser}
          selectedUser={selectedUser}
          checkedArtistUnknown={checkedArtistUnknown || hasCertificate}
          formState={formState}
          isShowCustomPaper={isShowCustomPaper}
          titleListUser={titleListUser}
          titleSaveUser={titleSaveUser}
          roleUser={roleUser}
        />
      )}

      {isShowInputArtistName && !selectedUser?.id && (
        <div>
          <FormInput
            {...register('artistName')}
            disabled
            labelStyle={classes.formInputLabel}
            textFieldStyle={classes.textFieldStyle}
            wrapFormInputStyle={classes.wrapFormInput}
          />
        </div>
      )}

      {statusTagRequest === STATUS_REQUEST_KEY.REQUEST_APPROVED && (
        <Text
          type="xs"
          mt={8}
        >
          {t('the_artist_has_approved_tagged_request')}
        </Text>
      )}

      {(selectedUser?.id || artistName) &&
        statusTagRequest !== STATUS_REQUEST_KEY.REQUEST_APPROVED &&
        !hasCertificate &&
        showText && (
          <Text
            type="sm"
            mt={8}
            onClick={handleSearchAgain}
            className={classes.textSearchAgain}
          >
            <Trans
              i18nKey="want_to_change_name_search_again"
              values={{ search: t('search_again') }}
            />
          </Text>
        )}

      {isShowCheckBoxCustom && (
        <FormCheckBoxController
          name="checkedArtistUnknown"
          control={control}
          onChange={handleCheckArtist}
          error={!!formState?.errors.checked}
          helperText={formState?.errors.checked?.message}
          textCheckBox={t('the_artist_is_unknown')}
          disabled={hasCertificate}
        />
      )}

      <DialogArtistName {...dialogArtistName} />
      <DialogCancelTagRequest {...dialogCancelTagRequest} />
    </>
  );
};

export default FormUserByRoleCollector;
