import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Text from 'commons/Text';
import { useRequestCertificateContext } from 'contexts/RequestCertificateContext';
import Routes from 'utils/Route';

import { artworkSchema } from '../ArtworkInfo/validations';
import { ownerSchema } from '../OwnerInfoArtistCreateCer/validate';
import useStyles from './styles';

const GroupButtonBottomCertificate = ({ onNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.account.user);
  const { ownerInfor, artworkInfo } = useRequestCertificateContext();
  const [canNextStep, setCanNextStep] = useState(false);

  const handleNextStep = () => {
    if (!canNextStep) {
      return;
    }
    onNextStep();
  };

  const handleCancel = () => {
    navigate(Routes.GalleryDetail(user?.id));
  };

  const validateSchema = async (schema, data) => {
    try {
      const value = await schema.validate(data);
      return !!value;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const validate = async () => {
      const isArtworkValidate = await validateSchema(artworkSchema, artworkInfo);
      const isOwnerValidate = await validateSchema(ownerSchema, ownerInfor);
      setCanNextStep(isArtworkValidate && isOwnerValidate);
    };

    validate();
  }, [artworkInfo, ownerInfor]);

  return (
    <Box className={classes.boxButtonBottom}>
      <div className={classes.wrapTextImportant}>
        <ErrorIcon />
        <Text
          className={classes.textImportant}
          type="md"
          fontWeightBold
        >
          {t('the_information_in_the_certificate')}
        </Text>
      </div>
      <div className={classes.groupButton}>
        <Button
          variant="outlined"
          className={classes.buttonCancel}
          onClick={handleCancel}
        >
          <Text
            className={classes.textButton}
            variant="span"
          >
            {t('cancel')}
          </Text>
        </Button>
        <Button
          disabled={!canNextStep}
          onClick={handleNextStep}
          variant="contained"
          className={classes.buttonNext}
        >
          <Text
            className={classes.textButton}
            variant="span"
          >
            {t('next')}
          </Text>
        </Button>
      </div>
    </Box>
  );
};

export default GroupButtonBottomCertificate;
