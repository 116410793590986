import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useDisplayText from 'commons/FilterArtwork/hooks/useDisplayText';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, STYLE_KEY } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { STYLE } = ARTWORK_FILTER_PARAMS;

const StyleTag = () => {
  const { data: styles = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.STYLE],
  });
  const getDisplayText = useDisplayText(STYLE_KEY.DISPLAY, STYLE_KEY.DISPLAY_VI);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedStyleParams = searchParams.get(STYLE)?.split(',') || [];
  const selectedStyles = styles.filter((style) =>
    selectedStyleParams.includes(style[STYLE_KEY.CHECKED]?.toString()),
  );

  const handleRemoveCategory = (style) => {
    const newSelectedStyleParams = [...selectedStyleParams].filter(
      (item) => item !== style[STYLE_KEY.CHECKED]?.toString(),
    );

    if (newSelectedStyleParams.length) {
      searchParams.set(STYLE, newSelectedStyleParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(STYLE);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {selectedStyles.map((style) => (
        <CommonTag
          key={style[STYLE_KEY.CHECKED]}
          text={getDisplayText(style)}
          onRemoveFilter={() => handleRemoveCategory(style)}
        />
      ))}
    </>
  );
};

export default StyleTag;
