import React from 'react';
import { useSearchParams } from 'react-router-dom';

import TabPanel from 'commons/TabPanel';

import TableArtistTagRequest from '../../Common/TableArtistTagRequest';
import TableShowAllRoleCollector from '../../TableCollectorPanel/TableShowAllRoleCollector';

const TableCollector = () => {
  const [searchParams] = useSearchParams();
  const tabValue = Number(searchParams.get('tab') || 0);

  return (
    <>
      <TabPanel
        value={tabValue}
        index={0}
      >
        <TableShowAllRoleCollector />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <TableArtistTagRequest />
      </TabPanel>
    </>
  );
};

export default TableCollector;
