import { Box, Button, Stack } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import useStyles from './styles';

const GroupActionButton = ({
  mainButtonText,
  subButtonLeftText,
  subButtonRightText,
  onMainButtonClick,
  disabledButtonNext,
  onSubButtonRight,
  onSubButtonLeft,
  groupActionButtonWrapStyle,
  subButtonRightStyle,
  extendUi,
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.wrapper, groupActionButtonWrapStyle)}>
      {extendUi}

      <Button
        variant="contained"
        fullWidth
        className={classes.btn}
        onClick={onMainButtonClick}
        disabled={disabledButtonNext}
      >
        {mainButtonText}
      </Button>

      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: 2 }}
      >
        <Button
          onClick={onSubButtonLeft}
          variant="outlined"
          fullWidth
          className={clsx(classes.btn, classes.btnBorderGrey)}
        >
          {subButtonLeftText}
        </Button>

        <Button
          onClick={onSubButtonRight}
          variant="outlined"
          fullWidth
          className={clsx(classes.btn, subButtonRightStyle)}
        >
          {subButtonRightText}
        </Button>
      </Stack>
    </Box>
  );
};

export default GroupActionButton;
