import { Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import TabPanel from 'commons/TabPanel';
import useGalleryArtworks from 'pages/Gallery/hooks/useGalleryArtworks';

import GalleryArtwork from '../GalleryArtwork';
import useStyles from './styles';

const GalleryTabContent = ({ visibleSetting }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { artistId } = useParams();
  const [tabValue, setTabValue] = useState(1);
  const { data: artworks, isPending } = useGalleryArtworks(artistId);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const getLabel = ({ text, number, isShowNumber }) => {
    if (!isShowNumber) {
      return text;
    }

    return `${text} ${number}`;
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        <Tab
          label={getLabel({
            text: t('all_works'),
            number: artworks?.count || '',
            isShowNumber: visibleSetting?.is_public_artwork,
          })}
          value={1}
          className={classes.tab}
        />
      </Tabs>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <GalleryArtwork
          artworks={artworks}
          isPending={isPending}
        />
      </TabPanel>
    </>
  );
};

export default GalleryTabContent;
