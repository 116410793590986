import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import DialogCustom from 'commons/DialogCustom';
import GroupActionButton from 'commons/GroupActionButton';
import MatchingBox from 'commons/MatchingBox';
import Text from 'commons/Text';
import TextArea from 'commons/TextArea';

import useApproveTagRequest from '../../../hooks/useApproveTagRequest';
import useRejectTagRequest from '../../../hooks/useRejectTagRequest';
import useStyles from './styles';

const approveSchema = yup.object().shape({
  artworkValue: yup.object().required('Artwork is require field'),
  editionValue: yup.object().required('Edition is require field'),
});

const GroupButtonReceiveTagRequest = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { mutate: approveRequest } = useApproveTagRequest(id);
  const { mutate: rejectRequest } = useRejectTagRequest(id);

  const [isShowApproveDialog, setIshowApproveDialog] = useState(false);
  const [isShowRejectDialog, setIshowRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const { artwork } = data;

  const hookForm = useForm({
    defaultValues: {
      artworkValue: artwork?.linked_artwork,
      editionValue: artwork?.editions[0]?.linked_edition,
    },
    mode: 'onChange',
    resolver: yupResolver(approveSchema),
  });
  const { setValue, handleSubmit } = hookForm;

  const handleCancelDialogApprove = () => setIshowApproveDialog(false);

  const handleSubmitApprove = (formData) => {
    approveRequest({
      artwork_link_id: formData?.artworkValue?.id,
      edition_link_number: formData?.editionValue?.edition_number,
      requested_artwork_id: artwork?.id,
      requested_edition_id: artwork?.editions[0]?.id,
    });
  };

  const handleApproveCertificateRequest = () => {
    setIshowApproveDialog(true);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleRejectRequest = () => setIshowRejectDialog(true);

  const handleCancelDialogReject = () => setIshowRejectDialog(false);

  const handleChangeTextReject = (e) => {
    const value = e.target.value;
    setRejectReason(value);
  };

  const handleClickReject = () => {
    rejectRequest({
      message: rejectReason,
    });
  };

  const handleArtworkChange = () => {
    setValue('editionValue', null, { shouldValidate: true });
  };

  return (
    <div>
      <GroupActionButton
        mainButtonText={t('approve_request')}
        subButtonLeftText={t('cancel')}
        subButtonRightText={t('reject_request')}
        onMainButtonClick={handleSubmit(handleApproveCertificateRequest)}
        onSubButtonLeft={handleCancel}
        onSubButtonRight={handleRejectRequest}
        extendUi={
          <MatchingBox
            artworkTitle={artwork?.title}
            totalEdition={artwork?.total_edition}
            artworkFormName={'artworkValue'}
            editionFormName={'editionValue'}
            onArtworkChange={handleArtworkChange}
            hookForm={hookForm}
            disabled={!!artwork?.linked_artwork}
          />
        }
        subButtonRightStyle={classes.subButtonRightStyle}
      />

      <DialogCustom
        isShowDialog={isShowApproveDialog}
        dialogTitle={t('approve_request')}
        textCancel={t('cancel')}
        textSubmit={t('approve')}
        onCancel={handleCancelDialogApprove}
        onSubmit={handleSubmit(handleSubmitApprove)}
        maxWidth={'md'}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonSizeMedium)}
        cancelButtonStyle={clsx(classes.buttonDialog, classes.buttonSizeMedium)}
      >
        <div className={classes.wrapDialogContent}>
          <Trans
            i18nKey="text_are_you_sure_approve_tag_request"
            values={{ name: data?.request_by?.name || '--' }}
          />
        </div>
      </DialogCustom>

      <DialogCustom
        isShowDialog={isShowRejectDialog}
        dialogTitle={t('reject_request')}
        textCancel={t('cancel')}
        textSubmit={t('reject')}
        onCancel={handleCancelDialogReject}
        onSubmit={handleClickReject}
        maxWidth={'md'}
        submitButtonStyle={clsx(classes.buttonDialog, classes.submitButtonRejectStyle)}
        cancelButtonStyle={classes.buttonDialog}
      >
        <div className={classes.wrapDialogContent}>
          <Text>{t('reject_reason')}</Text>
          <TextArea
            value={rejectReason}
            onChange={handleChangeTextReject}
            textMaxLength={200}
            placeholder={t('text_placeholder_reject_request')}
            wrapTextStyle={classes.wrapTextArea}
          />
        </div>
      </DialogCustom>
    </div>
  );
};

export default GroupButtonReceiveTagRequest;
