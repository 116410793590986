import React from 'react';
import { useSelector } from 'react-redux';

import { TESTID } from 'configs/Constant';

import useStyles from '../styles';
import ButtonGroup from './ButtonGroup';
import MenuLanguage from './MenuLanguage';
import MenuProfile from './MenuProfile';

const HeaderRight = ({ isPageLogin }) => {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <div className={classes.wrapHeaderRight}>
      <div className={classes.wrapGroupItem}>
        <MenuLanguage />

        {!isPageLogin && (
          <div>
            {isLoggedIn ? (
              <MenuProfile testid={TESTID.MENU_PROFILE} />
            ) : (
              <ButtonGroup testid={TESTID.BUTTON_LOGIN_GROUP} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderRight;
