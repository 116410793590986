import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'commons/Header';
import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const Layout = () => {
  const classes = useStyles();

  return (
    <div
      testid={TESTID.WRAP_LAYOUT}
      className={classes.wrapPrivateLayout}
    >
      <Header />
      <Outlet />
    </div>
  );
};

export default Layout;
