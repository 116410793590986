import { Table } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import API from 'configs/API';
import usePagination from 'hooks/usePagination';

import TableWrapper from '../../TableWrapper';
import TableArtistTagRequestBody from './TableArtistTagRequestBody';
import TableArtistTagRequestHead from './TableArtistTagRequestHead';

const TableArtistTagRequest = () => {
  const { data: tagrequests } = useQuery({ queryKey: [API.ARTWORK.LIST_ARTIST_TAG_REQUEST] });

  const count = tagrequests?.count || 0;
  const pageSize = tagrequests?.page_size || 10;

  const { page, totalPage, onPageChange } = usePagination(count, pageSize);

  const listTagrequests = tagrequests?.results || [];

  return (
    <TableWrapper
      page={page}
      totalPage={totalPage}
      onPageChange={onPageChange}
    >
      <Table>
        <TableArtistTagRequestHead />
        <TableArtistTagRequestBody
          listTagrequests={listTagrequests}
          page={page}
          pageSize={tagrequests?.page_size}
        />
      </Table>
    </TableWrapper>
  );
};

export default TableArtistTagRequest;
