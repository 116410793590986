import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formControlStyle: {
    '&.MuiFormControl-root': {
      width: '100%',
      marginTop: 8,
    },
  },
  valueLocationNone: {
    height: 15,
  },
  wrapIconLocation: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconAddLocation: {
    '&.MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginLeft: 10,
    },
  },
  textCreateLocation: {
    fontSize: 16,
    marginLeft: 5,
    width: '100%',
  },
}));

export default useStyles;
