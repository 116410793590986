import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tabs: {
    '&.MuiTabs-root': {
      marginTop: 40,
    },
  },
  tab: {
    '&.MuiTab-root': {
      marginRight: 32,
      padding: 0,
      borderRadius: 99,
      fontSize: 16,
      lineHeight: '150%',
      textTransform: 'capitalize',
      alignItems: 'flex-start',
      minWidth: 'unset',
      '&.Mui-selected': {
        fontWeight: 500,
      },
    },
  },
}));

export default useStyles;
