import { Avatar, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteSearchUser from 'commons/AutocompleteSearchUser';
import CheckBoxCustom from 'commons/CheckBoxCustom';
import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';
import API from 'configs/API';
import { TESTID, USER_ROLE_BY_KEY } from 'configs/Constant';
import CustomPaperSearchUser from 'features/Artwork/CustomPaperSearchUser';
import useDebounce from 'hooks/useDebounce';

import useStyles from './styles';

const FormArtistByRoleCollector = ({
  isShowInputArtistName,
  handleClickPaper,
  handleSearchAgain,
  checkedNotSureArtist,
  nameUser,
  isShowCheckBoxCustom,
  handleCheckArtist,
  handleClickSelectSaveUser,
  value,
  onChange,
  inputValue,
  onInputChange,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);
  const classes = useStyles();

  const debounceInputValue = useDebounce(inputValue);

  const { data: userFilter = [] } = useQuery({
    queryKey: [
      API.AUTH.SEARCH_USER,
      { search: debounceInputValue, role__in: USER_ROLE_BY_KEY.ARTIST, exclude__in: user?.id },
    ],
  });

  const { data: listSavedUser = [] } = useQuery({
    queryKey: [API.AUTH.SAVED_USER, { search: debounceInputValue }],
  });

  return (
    <>
      <Text
        fontWeightBold
        type="lg"
        mb={6}
        mt={12}
      >
        {t('artist')}
      </Text>

      {!isShowInputArtistName ? (
        <AutocompleteSearchUser
          value={value}
          onChange={onChange}
          userFilter={userFilter}
          inputValue={inputValue}
          onInputChange={onInputChange}
          PaperComponent={(props) => (
            <CustomPaperSearchUser
              {...props}
              titleSaveUser={t('new_artist_profile')}
              onClickPaper={handleClickPaper()}
              onClickSelectSaveUser={handleClickSelectSaveUser()}
              listSavedUser={listSavedUser}
            />
          )}
          titleList={t('active_artists')}
          selectUser={value}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('search_legal_name_nickname')}
              InputProps={{
                ...params.InputProps,
                startAdornment: value ? (
                  <Avatar
                    src={value?.avatar}
                    className={classes.avatar}
                  />
                ) : null,
              }}
            />
          )}
          getOptionLabel={(option) =>
            `${option?.legal_name} ${option?.name && `[${option?.name}]`}`
          }
          disabled={checkedNotSureArtist}
        />
      ) : (
        <FormInput
          value={nameUser}
          disabled
          labelStyle={classes.formInputLabel}
          textFieldStyle={classes.textFieldStyle}
          wrapFormInputStyle={classes.wrapFormInput}
        />
      )}

      {(value || nameUser) && (
        <Text
          type="sm"
          mt={8}
          onClick={handleSearchAgain}
          className={classes.textSearchAgain}
          clickAble
        >
          <Trans
            i18nKey="want_to_change_name_search_again"
            values={{ search: t('search_again') }}
          />
        </Text>
      )}

      {isShowCheckBoxCustom && (
        <CheckBoxCustom
          textCheckBox={t('the_artist_is_unknown')}
          checked={checkedNotSureArtist}
          onChange={handleCheckArtist}
          testid={TESTID.CHECK_BOX_PERIOD_ARTWORK}
        />
      )}
    </>
  );
};

export default FormArtistByRoleCollector;
