import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import Routes from 'utils/Route';

import useStyles from '../styles';

const ButtonGroup = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigateLogin = () => {
    navigate(Routes.Login);
  };

  // const handleNavigateSignUp = () => {
  //   navigate(Routes.SignUp);
  // };

  return (
    <div className={classes.wrapButtonGroup}>
      <div>
        <Button
          variant="outlined"
          onClick={handleNavigateLogin}
          className={classes.buttonLogin}
          testid={TESTID.BUTTON_LOGIN_PAGE_HOME}
        >
          <Text className={classes.textButton}>{t('login_in')}</Text>
        </Button>
      </div>
      {/* <div>
        <Button
          variant="contained"
          onClick={handleNavigateSignUp}
          className={classes.buttonSignUp}
          testid={TESTID.BUTTON_SIGN_UP_PAGE_HOME}
        >
          <Text className={classes.textButton}>{t('sign_up')}</Text>
        </Button>
      </div> */}
    </div>
  );
};
export default ButtonGroup;
