import AddIcon from '@mui/icons-material/Add';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './style';

const SelectLocation = ({ location, optionLocaltion, setLocation, setIsShowDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openSelectLocation, setOpenSelectLocation] = useState(false);

  const handleChangeLocation = (e) => {
    const value = e.target.value;
    setLocation(value);
  };

  const handleSelectLocation = () => {
    setOpenSelectLocation(true);
  };

  const handleCloseLocation = () => {
    setOpenSelectLocation(false);
  };

  const handleOpenDialogLocation = () => {
    setOpenSelectLocation(false);
    setIsShowDialog(true);
  };

  return (
    <>
      <Text
        fontWeightBold
        type="lg"
        mb={0}
        mt={16}
      >
        {t('artwork_location')}
      </Text>
      <FormControl
        className={classes.formControlStyle}
        variant="outlined"
      >
        <InputLabel>{t('select_location')}</InputLabel>
        <Select
          value={location}
          open={openSelectLocation}
          onChange={handleChangeLocation}
          onOpen={handleSelectLocation}
          onClose={handleCloseLocation}
          label={t('select_location')}
        >
          <MenuItem value="">
            <div className={classes.valueLocationNone} />
          </MenuItem>
          {optionLocaltion.map((option, index) => (
            <MenuItem
              key={index}
              value={option.id}
            >
              {option.location}
            </MenuItem>
          ))}
          <div>
            <div
              onClick={handleOpenDialogLocation}
              className={classes.wrapIconLocation}
            >
              <AddIcon className={classes.iconAddLocation} />
              <Text
                bold
                className={classes.textCreateLocation}
              >
                {t('create_new_artwork_location')}
              </Text>
            </div>
          </div>
        </Select>
      </FormControl>
    </>
  );
};

export default SelectLocation;
