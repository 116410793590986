import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import useStyles from './styles';

const ManageTabPanel = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const tabValue = Number(searchParams.get('tab') || 0);

  const handleChangeTabPanel = (e, newValue) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('tab', newValue);
    setSearchParams(newSearchParams, { replace: true });
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleChangeTabPanel}
      >
        <Tab
          label={t('all')}
          value={0}
          className={classes.tab}
        />
        <Tab
          label={t('tagged_request')}
          value={1}
          className={classes.tab}
        />
      </Tabs>
    </div>
  );
};

export default ManageTabPanel;
