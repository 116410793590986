import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import AuthLayout from 'commons/Auth/AuthLayout';
import Text from 'commons/Text';
import { USER_ROLE_BY_KEY } from 'configs/Constant';
import useUserAccount from 'hooks/useUserAccount';
import { ReactComponent as IconArtist } from 'images/icon_artist.svg';
import { ReactComponent as IconCollector } from 'images/icon_collector.svg';
import Routes from 'utils/Route';
import { ToastTopHelper, isUserInformationVerified } from 'utils/utils';

import AccountTypeOption from './AccountTypeOption';
import Header from './components/Header';
import useStyles from './styles';

const ChooseAccountType = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.account.user);
  const isVerifyInformation = isUserInformationVerified(user);
  const { updateUserAccount } = useUserAccount();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [selectedAccountType, setSelectedAccountType] = useState(null);

  const handleUploadProfileSuccess = () => {
    navigate(Routes.GalleryDetail(user?.id));
  };

  const handleClickContinue = () => {
    if (!selectedAccountType) {
      return ToastTopHelper.error(t('please_choose_account_type'));
    }
    const formData = new FormData();
    formData.append('role', selectedAccountType);
    updateUserAccount(formData, handleUploadProfileSuccess);
  };

  if (user?.role) {
    return (
      <Navigate
        to={Routes.GalleryDetail(user?.id)}
        replace
      />
    );
  }

  if (!isVerifyInformation) {
    return (
      <Navigate
        to={Routes.VerifyInformation}
        replace
      />
    );
  }

  if (!isLoggedIn) {
    return (
      <Navigate
        to={Routes.Login}
        replace
      />
    );
  }

  return (
    <div>
      <Header />
      <AuthLayout
        wrapLayoutStyle={classes.wrapLayoutStyle}
        rowStyle={classes.rowStyle}
      >
        <div className={classes.wrapForm}>
          <div className={classes.wrapTextTitle}>
            <Text
              fontWeight={700}
              className={classes.textChooseAccount}
            >
              {t('choose_account_type')}
            </Text>
            <Text className={classes.textNeedMoreInfo}>{t('if_you_need_more_info')}</Text>
          </div>

          <AccountTypeOption
            type={USER_ROLE_BY_KEY.ARTIST}
            selectedType={selectedAccountType}
            onSelect={setSelectedAccountType}
            icon={<IconArtist />}
            label="Artist"
          />

          <AccountTypeOption
            type={USER_ROLE_BY_KEY.COLLECTOR}
            selectedType={selectedAccountType}
            onSelect={setSelectedAccountType}
            icon={<IconCollector />}
            label="Collector"
          />

          <Button
            variant="contained"
            fullWidth
            onClick={handleClickContinue}
            className={classes.buttonSignUp}
          >
            <Text
              fontWeightBold
              type="lg"
            >
              {t('continue')}
            </Text>
          </Button>

          <div className={classes.line} />
        </div>
      </AuthLayout>
    </div>
  );
};

export default ChooseAccountType;
