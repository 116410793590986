import { Avatar, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SocialMedia from 'commons/SocialMedia';
import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { USER_ROLE_BY_ID } from 'configs/Constant';
import useRole from 'hooks/useRole';

import BoxInfoGroupButtonAction from '../BoxInfoGroupButtonAction';
import useStyles from './styles';

const BoxInfo = ({ userProfile }) => {
  const { isArtist } = useRole(userProfile?.role);

  const classes = useStyles();
  const { t } = useTranslation();

  const { visible_setting } = userProfile || {};

  return (
    <Box className={classes.wrapBoxInfo}>
      <Avatar
        src={userProfile?.avatar || ''}
        className={classes.avatar}
      />

      <div className={classes.wrapNameUserProfile}>
        <Text
          type="lg"
          mt={16}
          fontWeightBold
        >
          {userProfile?.name || ''}
        </Text>
        {userProfile?.legal_name && (
          <Text
            className={classes.nickNameUserProfile}
            type="lg"
            mt={16}
          >{`[${userProfile?.legal_name}]`}</Text>
        )}
      </div>

      <Text
        className={classes.text}
        type="sm"
      >
        {t(`${USER_ROLE_BY_ID[userProfile?.role] || ''}`)}
      </Text>

      {userProfile?.live_at && (
        <Box className={classes.wrapLocation}>
          <Text
            fontWeightRegular
            color={Colors.Grey8}
            type="xs"
            ml={8}
          >
            {userProfile?.live_at}
          </Text>
        </Box>
      )}

      {visible_setting?.is_public_social_media && <SocialMedia socials={userProfile?.socials} />}

      {isArtist && <BoxInfoGroupButtonAction visibleSetting={visible_setting} />}
    </Box>
  );
};

export default BoxInfo;
