import { Button, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import { ReactComponent as ViewIcon } from 'images/logo-visibility.svg';
import Routes from 'utils/Route';

import UserSetting from './UserSetting';
import useStyles from './styles';

const BoxInfoGroupButtonAction = ({ visibleSetting }) => {
  const classes = useStyles();
  const { artistId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useSelector((state) => state.auth.account.user);

  const isOwner = user?.id === parseInt(artistId);

  const handleNavigateToArtistProfile = () => {
    navigate(Routes.ArtistProfile(artistId));
  };

  return (
    <Stack
      direction="row"
      sx={{ width: '100%', mt: 2 }}
      spacing={1}
    >
      {visibleSetting?.is_public_profile && (
        <Button
          variant="outlined"
          fullWidth
          className={classes.button}
          onClick={handleNavigateToArtistProfile}
          testid={TESTID.BUTTON_EDIT_PROFILE}
        >
          <ViewIcon className={classes.viewIcon} />
          <Text
            fontWeightMedium
            type="md"
            ml={8}
          >
            {t('view_artist_profile')}
          </Text>
        </Button>
      )}

      {isOwner && <UserSetting visibleSetting={visibleSetting} />}
    </Stack>
  );
};

export default BoxInfoGroupButtonAction;
