import { List } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useRole from 'hooks/useRole';
import Routes from 'utils/Route';

import { ReactComponent as IconCertificate } from '../../images/icon_certificate.svg';
import { ReactComponent as IconImage } from '../../images/icon_image.svg';
import { ReactComponent as IconImageEdition } from '../../images/icon_image_edition.svg';
import ListMenuItem from './ListMenuItem';

const ListMenu = ({ open }) => {
  const { t } = useTranslation();
  const { isArtist } = useRole();
  const navigate = useNavigate();

  const extractLastPathSegment = () => {
    const path = window.location.pathname;
    const lastSegment = path?.split('/').filter(Boolean).pop();
    return lastSegment;
  };

  const handleClickManageEdition = () => {
    navigate(Routes.ManageEdition);
  };

  const handleClickManageCertificate = () => {
    navigate(Routes.ManageCertificate);
  };

  const handleClickManageArtwork = () => {
    navigate(Routes.ManageArtwork);
  };

  return (
    <List>
      <ListMenuItem
        open={open}
        text={t('artworks')}
        icon={<IconImage />}
        isActive={extractLastPathSegment() === 'manage-artwork'}
        onClick={handleClickManageArtwork}
      />
      {isArtist && (
        <ListMenuItem
          open={open}
          text={t('editions')}
          icon={<IconImageEdition />}
          isActive={extractLastPathSegment() === 'manage-edition'}
          onClick={handleClickManageEdition}
        />
      )}

      <ListMenuItem
        open={open}
        text={t('certificates')}
        icon={<IconCertificate />}
        isActive={extractLastPathSegment() === 'manage-certificate'}
        onClick={handleClickManageCertificate}
      />
    </List>
  );
};

export default ListMenu;
