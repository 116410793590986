import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import API from 'configs/API';
import GridLayout from 'layouts/GridLayout';
import Routes from 'utils/Route';
import { axiosGet } from 'utils/apis/axios';

import ArtworkDetailLeft from './components/ArtworkDetailLeft';
import ArtworkDetailRight from './components/ArtworkDetailRight';

const ArtworkDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const user = useSelector((state) => state.auth.account.user);
  const [artwork, setArtwork] = useState({});
  const [loading, setLoading] = useState(true);

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.GalleryDetail(user?.id),
      text: t('gallery'),
    },
    {
      to: Routes.ArtworkDetail(id),
      text: t('artwork_detail'),
      active: true,
    },
  ];

  useEffect(() => {
    const getArtwork = async () => {
      setLoading(true);
      const { success, data } = await axiosGet(API.ARTWORK.ARTWORK_DETAIL(id));

      if (success) {
        setArtwork(data);
      }

      setLoading(false);
    };

    getArtwork();
  }, [id]);

  return (
    <Container>
      <GridLayout
        spacing={5}
        leftColumnProps={{
          lg: 3,
        }}
        rightColumnProps={{
          lg: 9,
        }}
      >
        <ArtworkDetailLeft
          breadCrumbs={breadCrumbs}
          artwork={artwork}
        />
        <ArtworkDetailRight
          loading={loading}
          artwork={artwork}
        />
      </GridLayout>
    </Container>
  );
};

export default ArtworkDetail;
