import { Stack } from '@mui/material';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Text from 'commons/Text';
import LogoPlatForm from 'images/logo-platform.svg';

import useStyles from './styles';

const CertificateHeader = ({ certificate }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { id, legal_name } = certificate?.issued_by || {};

  const time = moment(certificate?.created_at);
  const day = time.format('DD');
  const month = time.format('MMMM');
  const year = time.format('YYYY');
  const timeDay = `${month} ${day}, ${year}`;

  return (
    <>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
      >
        <div>
          <Text
            className={classes.textHeading}
            type="3xl"
            fontWeightRegular
          >
            {t('certificate_authenticity')}
          </Text>
          <Text
            fontWeightBold
            type="2xl"
            className={classes.textHeadingTwo}
          >
            {t('of Original Artwork')}
          </Text>
        </div>

        <img
          src={LogoPlatForm}
          className={classes.logoPlatform}
        />
      </Stack>

      <Text
        type="2xs"
        className={classes.textCertificateNumber}
        mt={8}
      >
        {t('certificate_number')} | {certificate?.id}
      </Text>

      <Text
        title
        type="3xl"
        className={classes.textNameArtist}
      >
        {legal_name}
      </Text>

      <Text
        mt={8}
        className={clsx(classes.subText, {
          [classes.hideText]: !id,
        })}
        type="2xs"
      >
        {`${t('account_id')} | ${id}`}
      </Text>

      <Text
        mt={24}
        type="sm"
        className={classes.textLetterSpacing}
      >
        <Trans
          i18nKey="this_certifies_the_artwork_presented_below"
          values={{ name: legal_name, timeDay }}
        />
      </Text>
    </>
  );
};

export default CertificateHeader;
