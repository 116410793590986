import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroup from 'commons/FilterArtwork/CheckBoxGroup';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, CATEGORY_KEY } from 'configs/FilterArtwork';

const { CATEGORY } = ARTWORK_FILTER_PARAMS;

const FilterCategory = () => {
  const { data: categories = [] } = useQuery({
    queryKey: [API.ARTWORK.CATEOGRY],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCategoryParams = searchParams.get(CATEGORY)?.split(',') || [];

  const handleCheckboxChange = (category) => {
    const isChecked = selectedCategoryParams.find(
      (item) => item === category[CATEGORY_KEY.CHECKED],
    );

    searchParams.delete('page');

    if (isChecked) {
      const newSelectedCategoryParams = [...selectedCategoryParams].filter(
        (item) => item !== category[CATEGORY_KEY.CHECKED],
      );

      if (newSelectedCategoryParams.length) {
        searchParams.set(CATEGORY, newSelectedCategoryParams);
        setSearchParams(searchParams);
      } else {
        searchParams.delete(CATEGORY);
        setSearchParams(searchParams);
      }
      return;
    }

    searchParams.set(CATEGORY, [...selectedCategoryParams, category[CATEGORY_KEY.CHECKED]]);
    setSearchParams(searchParams);
  };

  return (
    <CheckBoxGroup
      listCheckBox={categories}
      selectedFilters={selectedCategoryParams}
      onCheckboxChange={handleCheckboxChange}
      textDisplayKey={CATEGORY_KEY.CHECKED}
      checkedKey={CATEGORY_KEY.CHECKED}
      frontEndTranslate
    />
  );
};

export default FilterCategory;
