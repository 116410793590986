import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Popover, TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArtworkCol from 'commons/ArtworkCol';
import DialogConfirmDeleteArtwork from 'commons/Dialog/DialogConfirmDeleteArtwork';
import Text from 'commons/Text';
import { getLabelForOption } from 'configs/Constant';
import useDeleteArtwork from 'hooks/useDeleteArtwork';
import useStatusCanRequestCertificate from 'react-query/hooks/queries/useStatusCanRequestCertificate';
import Routes from 'utils/Route';
import { ToastTopHelper } from 'utils/utils';

import TableCellContent from './TableCellContent';
import useStyles from './styles';

const getSizeData = (size) => {
  const { height, width, depth } = size || {};
  if (!height || !width) return '';
  return `${height} x ${width}${depth ? ` x ${depth}` : ''} cm`;
};

const ArtworkRow = ({ artwork, index, page, pageSize }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const language = useSelector((state) => state?.language?.currentLanguage);
  const { data: status } = useStatusCanRequestCertificate();
  const { mutate: deleteArtwork } = useDeleteArtwork();

  const integerPrice = parseInt(artwork?.price, 10);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowDialogDelete, setIsShowDialogDelete] = useState(false);

  const displayIndex = (page - 1) * pageSize + (index + 1);
  const sizeData = getSizeData(artwork?.size);
  const isUniqueEdition = artwork?.total_edition === 1;
  const numberEditionHadCer = artwork?.editions?.reduce((acc, edition) => {
    return acc + (edition?.id_certificate !== null ? 1 : 0);
  }, 0);
  const isAllEditionHadCer = numberEditionHadCer === artwork?.total_edition;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const canRequestCertificate =
    Array.isArray(status) && status?.find((item) => item?.value === artwork?.status?.value);

  const handleClickViewDetail = () => {
    navigate(Routes.ManageEdition, { state: { title: artwork?.title } });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestCertificate = () => {
    navigate(Routes.CreateCertificate(artwork?.id));
  };

  const handleNavigateEditArtwork = (e) => {
    e.stopPropagation();
    navigate(Routes.EditArtwork(artwork?.id));
  };

  const handleDeleteArtwork = (e) => {
    e.stopPropagation();
    deleteArtwork(artwork?.id, {
      onSuccess: () => {
        ToastTopHelper.success(
          t(
            artwork?.has_certificate ? 'text_move_artwork_to_store' : 'text_delete_artwork_success',
          ),
        );
      },
    });

    setIsShowDialogDelete(false);
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setIsShowDialogDelete(false);
  };

  const handleShowPopUpConfirmDelete = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    setIsShowDialogDelete(true);
  };

  const shouldHandleCertificateRequest =
    canRequestCertificate &&
    ((isUniqueEdition && !artwork?.has_certificate) || (!isUniqueEdition && !isAllEditionHadCer));

  const handleClickCreateCer = () => {
    if (shouldHandleCertificateRequest) {
      handleRequestCertificate();
    }
  };

  const isCertificateDisabled =
    !canRequestCertificate ||
    (isUniqueEdition && artwork?.has_certificate) ||
    (!isUniqueEdition && isAllEditionHadCer);

  return (
    <TableRow
      key={index}
      className={clsx(classes.tableRowBorder)}
    >
      <TableCell>{displayIndex}</TableCell>
      <TableCell>
        <ArtworkCol
          id={artwork?.id}
          image={artwork?.images[0]}
          title={artwork?.title}
          code={artwork?.certificate?.code}
          isLink
        />
      </TableCell>
      <TableCell>{artwork?.is_public ? t('public') : t('private')}</TableCell>
      <TableCell>{getLabelForOption(artwork?.medium, language)}</TableCell>
      <TableCell>{artwork?.year_created || artwork?.period_created}</TableCell>
      <TableCell>{sizeData}</TableCell>
      <TableCell>{integerPrice ? `${integerPrice} ${artwork?.currency}` : '--'}</TableCell>
      <TableCell>{isUniqueEdition ? t('unique') : artwork?.total_edition}</TableCell>
      <TableCell>{isUniqueEdition ? artwork?.location?.location : t('view_detail')}</TableCell>
      <TableCell>
        <TableCellContent
          isUnique={isUniqueEdition}
          contentUnique={t(artwork?.status?.key)}
          contentGeneral={t('view_detail')}
          onClickViewDetail={handleClickViewDetail}
        />
      </TableCell>
      <TableCell>
        <div className={classes.wrapIcon}>
          <MoreHorizOutlinedIcon
            onClick={handleClick}
            className={classes.iconOutLine}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            className={classes.popover}
          >
            <Text
              onClick={handleClickCreateCer}
              className={clsx(classes.textItemPopover, {
                [classes.disable]: isCertificateDisabled,
              })}
            >
              <Text type="ml">{t('create_certificate')}</Text>
            </Text>

            <Text
              onClick={handleNavigateEditArtwork}
              className={clsx(classes.textItemPopover)}
            >
              {t('edit_artwork')}
            </Text>

            <Text
              onClick={handleShowPopUpConfirmDelete}
              className={classes.textItemDelete}
            >
              {t('delete_artwork')}
            </Text>
          </Popover>
        </div>
      </TableCell>

      <DialogConfirmDeleteArtwork
        isShowDialog={isShowDialogDelete}
        onCancel={handleCancelDelete}
        onSubmit={handleDeleteArtwork}
      />
    </TableRow>
  );
};

export default ArtworkRow;
