import { Box, Typography } from '@mui/material';
import React from 'react';

import Header from 'commons/Header';
import Colors from 'configs/Colors';

const PageNotFound = () => {
  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: Colors.Primary,
        }}
      >
        <Typography
          variant="h1"
          style={{ color: 'white' }}
        >
          404
        </Typography>
      </Box>
    </Box>
  );
};

export default PageNotFound;
