import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useDisplayText from 'commons/FilterArtwork/hooks/useDisplayText';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, MEDIUM_KEY } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { MEDIUM } = ARTWORK_FILTER_PARAMS;

const MediumTag = () => {
  const { data: mediums = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.MEDIUM],
  });
  const getDisplayText = useDisplayText(MEDIUM_KEY.DISPLAY, MEDIUM_KEY.DISPLAY_VI);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedMediumParams = searchParams.get(MEDIUM)?.split(',') || [];
  const selectedMediums = mediums.filter((medium) =>
    selectedMediumParams.includes(medium[MEDIUM_KEY.CHECKED]?.toString()),
  );

  const handleRemoveCategory = (medium) => {
    const newSelectedMediumParams = [...selectedMediumParams].filter(
      (item) => item !== medium[MEDIUM_KEY.CHECKED]?.toString(),
    );

    if (newSelectedMediumParams.length) {
      searchParams.set(MEDIUM, newSelectedMediumParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(MEDIUM);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {selectedMediums.map((medium) => (
        <CommonTag
          key={medium[MEDIUM_KEY.CHECKED]}
          text={getDisplayText(medium)}
          onRemoveFilter={() => handleRemoveCategory(medium)}
        />
      ))}
    </>
  );
};

export default MediumTag;
