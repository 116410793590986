import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import { TESTID } from 'configs/Constant';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import SectionWrapper from 'pages/EditArtistProfile/commons/SectionWrapper';
import YearDescriptionInput from 'pages/EditArtistProfile/commons/YearDescriptionInput';
import { isFieldValid, isValidYear } from 'utils/Validation';
import { axiosDelete, axiosGet, axiosPost } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import AwardListGrid from './components/AwardListGrid';
import DialogEditAward from './components/DialogEditAward';
import ImageListGrid from './components/ImageListGrid';
import UploadImageAward from './components/UploadImageAward';
import useStyles from './styles';

const ArtistAward = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { uploadListImage, generateImageKey } = useUploadImageS3();

  const [listUploadedImages, setListUploadedImages] = useState([]);
  const [listAward, setListAward] = useState([]);
  const [year, setYear] = useState('');
  const [description, setDescription] = useState('');
  const [selectedAward, setSelectedAward] = useState({});
  const [isPublic, setIsPublic] = useState(true);
  const [isShowDialogDelete, setIsShowDialogDelete] = useState(false);
  const [isShowDialogEdit, setIsShowDialogEdit] = useState(false);
  const [editedYear, setEditedYear] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedIsPublic, setEditedIsPublic] = useState(true);
  const [editedListAward, setEditedListAward] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [wrongYear, setWrongYear] = useState(false);
  const isYearValid = isFieldValid(year, wrongYear);

  const isValid = description && isYearValid && !isDisabled;

  const handleSwitchChange = (e) => {
    const isHidePrice = e.target.checked;
    setIsPublic(isHidePrice);
  };

  const handleChangeYear = (e) => {
    const value = e.target.value;
    setWrongYear(!isValidYear(value));
    setYear(value);
  };

  const handleChangeDescription = (e) => {
    const value = e.target.value;
    if (value?.length > 200) {
      return;
    }
    setDescription(value);
  };

  const handleUploadImage = async (e) => {
    const file = e?.target?.files[0];
    if (!file || listUploadedImages.length >= 5) {
      return;
    }
    const imageKey = generateImageKey(file);
    const uri = URL.createObjectURL(file);

    const data = {
      uri,
      uploadFile: file,
      imageKey,
    };
    setListUploadedImages([...listUploadedImages, { ...data }]);
  };

  const handleRemoveImage = (objectImage) => {
    const listImageClone = [...listUploadedImages];

    const newListImage = listImageClone.filter((item) => {
      if (item?.uri === objectImage?.uri) {
        URL.revokeObjectURL(item?.uri);
      }
      return item?.uri !== objectImage?.uri;
    });

    setListUploadedImages(newListImage);
  };

  const getListAward = async () => {
    const { success, data } = await axiosGet(API.AUTH.AWARD.LIST);
    if (success) {
      setListAward(data);
    }
  };

  const createUserAward = async () => {
    let imagesKey = listUploadedImages.map((image) => image?.imageKey);

    const formData = {
      year: year,
      description: description,
      is_public: isPublic,
      images: imagesKey,
    };

    const { success } = await axiosPost(API.AUTH.AWARD.CREATE, formData);

    if (success) {
      getListAward();
      setDescription('');
      setYear('');
      setListUploadedImages([]);
      setIsPublic(true);
      setIsDisabled(false);
      ToastTopHelper.success(t('create_successfully'));
    } else {
      setIsDisabled(false);
      ToastTopHelper.error(t('create_award_failed'));
    }
  };

  const handleClickAdd = async () => {
    setIsDisabled(true);
    const uploadS3Response = await uploadListImage(listUploadedImages);
    const allSuccessful = uploadS3Response.every((response) => response?.success === true);

    if (allSuccessful) {
      createUserAward();
    } else {
      setIsDisabled(false);
      ToastTopHelper.error(t('upload_file_failed'));
    }
  };

  const handleCancelAwardDeletion = () => {
    setIsShowDialogDelete(false);
  };

  const handleSubmitAwardDeletion = async () => {
    const { success } = await axiosDelete(API.AUTH.AWARD.DETAIL(selectedAward?.id));
    if (success) {
      getListAward();
      setIsShowDialogDelete(false);
      ToastTopHelper.success(t('delete_success'));
    }
  };

  const handleOpenDialogDelete = (item) => {
    setSelectedAward(item);
    setIsShowDialogDelete(true);
  };

  const handleOpenDialogEdit = (item) => {
    setIsShowDialogEdit(true);
    setSelectedAward(item);
    setEditedYear(item?.year);
    setEditedDescription(item?.description);
    setEditedIsPublic(item?.is_public);
    setEditedListAward(item?.list_image);
  };

  useEffect(() => {
    getListAward();
  }, []);

  return (
    <SectionWrapper heading={t('awards')}>
      <Box className={classes.bodyBox}>
        <YearDescriptionInput
          year={year}
          description={description}
          onChangeYear={handleChangeYear}
          onChangeDescription={handleChangeDescription}
          errorYear={wrongYear}
          helperTextYear={wrongYear && t('please_enter_correct_year')}
          testid={TESTID.YEAR_DESCRIPTION_INPUT}
        />
        <UploadImageAward
          listImage={listUploadedImages}
          isChecked={isPublic}
          onUploadImage={handleUploadImage}
          onSwitchChange={handleSwitchChange}
          testid={TESTID.UPLOAD_IMAGE_AWARD}
        />
        <Text className={classes.textCanUploadFile}>{t('you_can_upload_file_with_format')}</Text>
        <ImageListGrid
          listImage={listUploadedImages}
          onClickRemoveImage={handleRemoveImage}
        />
        <div className={classes.wrapButtonAdd}>
          <Button
            variant="contained"
            disabled={!isValid}
            onClick={handleClickAdd}
            className={classes.buttonAdd}
            testid={TESTID.BUTTON_ADD_ADWARD}
          >
            {t('add')}
          </Button>
        </div>
      </Box>
      <Text className={classes.textAddEventAutomatic}>{t('added_events_will_automatically')}</Text>
      <AwardListGrid
        listAward={listAward}
        onClickOpenDialogDelete={handleOpenDialogDelete}
        onClickOpenDialogEdit={handleOpenDialogEdit}
        testid={TESTID.AWARD_LIST_GRID}
      />

      <DialogEditAward
        isShowDialog={isShowDialogEdit}
        editedYear={editedYear}
        editedDescription={editedDescription}
        editedIsPublic={editedIsPublic}
        editedListAward={editedListAward}
        selectedAward={selectedAward}
        setEditYear={setEditedYear}
        setEditDescription={setEditedDescription}
        setEditedIsPublic={setEditedIsPublic}
        setIsShowDialogEdit={setIsShowDialogEdit}
        getListAward={getListAward}
      />
      <DialogCustom
        isShowDialog={isShowDialogDelete}
        textCancel={t('cancel')}
        textSubmit={t('delete')}
        dialogTitle={t('delete_award')}
        onCancel={handleCancelAwardDeletion}
        onSubmit={handleSubmitAwardDeletion}
        testid={TESTID.DIALOG_BUTTON_DELETE_AWARD}
      >
        {t('are_you_sure_you_want_delete_your', { name: t('awards') })}
        <Text
          bold
          mt={4}
        >
          {selectedAward?.description}
        </Text>
      </DialogCustom>
    </SectionWrapper>
  );
};

export default ArtistAward;
