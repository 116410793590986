import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';

import ImageCustom from 'commons/ImageCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import { axiosGet } from 'utils/apis/axios';

import useStyles from './styles';

const SpecialWorks = ({ title, userId }) => {
  const classes = useStyles();
  const [artwork, setArtwork] = useState({});

  useEffect(() => {
    const getArtWork = async () => {
      const { success, data } = await axiosGet(API.ARTWORK.ARTWORK, {
        params: {
          user_id: userId,
        },
      });
      if (success) {
        setArtwork(data);
      }
    };

    getArtWork();
  }, [userId]);

  const listSpecialWorks = artwork?.results?.slice(0, 4) || [];

  if (!listSpecialWorks?.length) {
    return null;
  }

  return (
    <Box className={classes.wrapBox}>
      <Text
        fontWeightBold
        type="xl"
      >
        {title}
      </Text>
      <div className={classes.wrapListArtwork}>
        <Grid
          container
          spacing={3}
        >
          {listSpecialWorks.map((item) => (
            <Grid
              key={item?.id}
              item
              xs={12}
              lg={3}
            >
              <ImageCustom
                src={item?.images[0]}
                imageStyle={classes.image}
                loadingStyle={classes.imageLoading}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default SpecialWorks;
