import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { TESTID } from 'configs/Constant';

import DisplayStatement from '../DisplayStatement';
import EditStatement from '../EditStatement';

const RenderStatement = ({
  isEditState,
  statement,
  isOwner,
  updateAccountStatement,
  handleChangestatement,
  handleClickCancel,
}) => {
  const { t } = useTranslation();

  const hasStatement = statement && statement.trim().length;

  if (isOwner && isEditState) {
    return (
      <EditStatement
        statement={statement}
        onStatementChange={handleChangestatement}
        onCancel={handleClickCancel}
        onSave={updateAccountStatement}
      />
    );
  }

  if (isOwner && !hasStatement) {
    return (
      <Text
        color={Colors.Grey6}
        type="Body"
        mt={8}
        testid={TESTID.TEXT_GUIDE_ADD_STATEMENT}
        fontWeightRegular
      >
        {t('text_guide_add_statement')}
      </Text>
    );
  }

  if (!hasStatement) {
    return null;
  }

  return <DisplayStatement statement={statement} />;
};

export default RenderStatement;
