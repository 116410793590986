import { Avatar, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import ImageCustom from 'commons/ImageCustom';
import PaginationCustom from 'commons/PaginationCustom';
import Text from 'commons/Text';
import Routes from 'utils/Route';

import useStyles from './styles';

const ListArtist = ({ artists }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const listArtist = artists?.results || [];
  const { count, page_size } = artists || {};
  const page = Number(searchParams.get('page')) || 1;
  const totalPage = Math.ceil(count / page_size);

  const handleClickItemArtist = (item) => {
    navigate(Routes.GalleryDetail(item?.id));
  };

  const handlePageChange = (e, page) => {
    if (page <= 1) {
      searchParams.delete('page');
      setSearchParams(searchParams);
      return;
    }

    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.wrapListArtist}>
      <Grid
        container
        spacing={3}
      >
        {listArtist.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            lg={2}
          >
            <div
              className={classes.wrapItemImage}
              onClick={() => handleClickItemArtist(item)}
            >
              {item?.first_artwork_images ? (
                <ImageCustom
                  src={item?.first_artwork_images}
                  imageStyle={classes.image}
                />
              ) : (
                <div className={classes.imageNotDisplay} />
              )}
              <div className={classes.wrapAvatar}>
                <Avatar
                  className={classes.avatarProfile}
                  src={item?.profile?.image_portrait}
                />
              </div>
              <Text
                fontWeightBold
                className={classes.textNameProfile}
              >
                {item?.legal_name}
              </Text>
              {item?.name && (
                <Text
                  fontWeight={300}
                  className={classes.textNickNameProfile}
                >{`[${item?.name}]`}</Text>
              )}
            </div>
          </Grid>
        ))}
      </Grid>

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default ListArtist;
