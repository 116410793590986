import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableNoBorder: {
    '& .MuiTableCell-body': {
      borderBottom: 'none',
    },
  },
  tableRowBorder: {
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
  tableEvenRow: {
    '& .MuiTableCell-body': {
      background: Colors.Grey1,
    },
  },
}));

export default useStyles;
