import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import VisibilityOption from 'commons/BoxStatusArtwork/components/VisibilityOption';
import CheckBoxCustom from 'commons/CheckBoxCustom';
import PriceInput from 'commons/PriceInput';
import Text from 'commons/Text';
import { OptionsCurrency } from 'configs/Constant';
import { useArtworkContext } from 'pages/UploadArtwork/ArtworkContext/useArtworkContext';

import useStyles from './styles';

const StatusPriceRoleArtist = ({ status }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { artworkData, updateArtwork, setValidationStatusPrice } = useArtworkContext();
  const { price, currency, isHidePrice, isAskPrice, visibility, contact } = artworkData;
  const [isCheckedHidePrice, setIsCheckedHidePrice] = useState(false);
  const [isCheckedAskPrice, setIsCheckedAskPrice] = useState(true);

  const handleChangeCurrency = (e) => {
    const newCurrency = e.target.value;
    updateArtwork({ currency: newCurrency });
  };

  const handleChangePrice = (e) => {
    const newPrice = e.target.value;
    if (newPrice < 0 || newPrice?.length > 12) {
      return;
    }
    updateArtwork({ price: newPrice });
  };

  const handleClickVisiblePrice = (e) => {
    const isCheckHidePrice = e.target.checked;
    setIsCheckedHidePrice(isCheckHidePrice);
    updateArtwork({
      isHidePrice: isCheckHidePrice,
      isAskPrice: isCheckHidePrice ? false : isCheckedAskPrice,
    });
  };

  const handleClickVisibleAskPrice = (e) => {
    const isCheckAskPrice = e.target.checked;
    setIsCheckedAskPrice(isCheckAskPrice);
    const updateData = {
      isAskPrice: isCheckAskPrice,
      isHidePrice: isCheckAskPrice ? false : isCheckedHidePrice,
    };
    if (isCheckAskPrice) {
      updateData.price = '';
    }
    updateArtwork(updateData);
  };

  const handleChangeVisibility = (e, newValue) => {
    updateArtwork({
      visibility: newValue,
    });
  };

  const handleChangeContact = (e) => {
    const value = e.target.value;
    if (value.length > 200) {
      return;
    }
    updateArtwork({
      contact: value,
    });
  };

  useEffect(() => {
    setValidationStatusPrice(true);
    updateArtwork({ isAskPrice: false, isHidePrice: false, price: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setIsCheckedHidePrice(isHidePrice);
    setIsCheckedAskPrice(isAskPrice);
  }, [isAskPrice, isHidePrice]);

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Text
          mt={14}
          fontWeightBold
          type="lg"
        >
          {t('price')}
          <span className={classes.require}>*</span>
        </Text>

        <PriceInput
          options={OptionsCurrency}
          price={price}
          currency={currency}
          onChangePrice={handleChangePrice}
          onCurrencyChange={handleChangeCurrency}
          disabled={isCheckedAskPrice}
          inputProps={{
            'aria-label': t('price'),
          }}
        />

        <CheckBoxCustom
          textCheckBox={t('hide_price_only_show_to_me')}
          checked={isCheckedHidePrice}
          onChange={handleClickVisiblePrice}
        />

        <CheckBoxCustom
          textCheckBox={t('ask_for_price')}
          checked={isCheckedAskPrice}
          onChange={handleClickVisibleAskPrice}
          wrapCheckBoxStyle={classes.wrapCheckBoxStyle}
        />

        {isCheckedAskPrice && (
          <div>
            <Text className={classes.textYourContact}>
              {t('your_contact_information')}
              <span className={classes.require}>*</span>
            </Text>
            <TextField
              value={contact}
              onChange={handleChangeContact}
              placeholder={t('enter_your_email_social_link')}
              className={classes.textContactInfomation}
              inputProps={{
                'aria-label': t('your_contact_information'),
              }}
            />
          </div>
        )}
      </Box>

      <VisibilityOption
        value={visibility}
        onChange={handleChangeVisibility}
      />
    </>
  );
};

export default StatusPriceRoleArtist;
