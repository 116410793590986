import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  avatar: {
    '&.MuiAvatar-root': {
      marginRight: 8,
      width: 24,
      height: 24,
    },
  },
  wrapFormInput: {
    marginTop: 0,
  },
  textFieldStyle: {
    '&.MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      padding: '16px 14px',
    },
  },
}));

export default useStyles;
