import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import clsx from 'clsx';

import Text from 'commons/Text';

import useStyles from './styles';

const DialogCustom = ({
  children,
  isShowDialog = false,
  onCancel,
  onSubmit,
  dialogTitle,
  textSubmit,
  disabledButton,
  maxWidth = 'sm',
  textCancel = '',
  fullWidth = true,
  loading,
  dialogActionStyle,
  cancelButtonStyle,
  submitButtonStyle,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onCancel}
      open={isShowDialog}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={classes.dialog}
      {...props}
    >
      <div className={classes.dialogHeader}>
        <div className={classes.wrapHeaderTitle}>
          <Text
            type="H3"
            fontWeightBold
          >
            {dialogTitle}
          </Text>

          <CloseIcon
            onClick={onCancel}
            className={classes.closeIcon}
          />
        </div>
      </div>

      <DialogContent className={classes.dialogContent}>{children}</DialogContent>

      <DialogActions className={clsx(classes.dialogAction, dialogActionStyle)}>
        {textCancel && (
          <Button
            variant="outlined"
            onClick={onCancel}
            className={cancelButtonStyle}
          >
            {textCancel}
          </Button>
        )}
        {textSubmit && (
          <Button
            variant="contained"
            disabled={disabledButton || loading}
            onClick={onSubmit}
            className={submitButtonStyle}
          >
            {textSubmit}

            {loading && (
              <CircularProgress
                color="inherit"
                className={classes.loading}
                size={16}
              />
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogCustom;
