import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  textArtist: {
    fontSize: 14,
  },
  textSearchAgain: {
    cursor: 'pointer',
  },
  formInputLabel: {
    padding: 0,
    marginBottom: 8,
  },
  wrapFormInput: {
    marginTop: -5,
  },
  btn: {
    '&.MuiButton-root': {
      padding: '2px 6px',
      marginTop: 8,
    },
  },
}));

export default useStyles;
