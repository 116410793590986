import { Stack, FormLabel } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArtworkUploadImage from 'commons/ArtworkUploadImage';
import { TESTID } from 'configs/Constant';
import useImageCompression from 'hooks/useImageCompression';
import useUploadImageS3 from 'hooks/useUploadImageS3';

import EditArtworkSection from '../EditArtworkSection';
import useStyles from './styles';

const IMAGE_KEYS = {
  IMAGE1: 'image1',
  IMAGE2: 'image2',
  IMAGE3: 'image3',
  IMAGE4: 'image4',
  IMAGE5: 'image5',
};

const EditArtworkImages = ({ artworkFormState }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { generateImageKey } = useUploadImageS3();
  const { compressImage } = useImageCompression();

  const { setValue, watch, formState, register } = artworkFormState;

  const hasCertificate = formState?.defaultValues.hasCertificate;

  const listImage = watch('images') || [];
  const listKeyImage = watch('keyImages') || [];

  const [displayImage, setDisplayImage] = useState({
    [IMAGE_KEYS.IMAGE1]: listImage[0],
    [IMAGE_KEYS.IMAGE2]: listImage[1],
    [IMAGE_KEYS.IMAGE3]: listImage[2],
    [IMAGE_KEYS.IMAGE4]: listImage[3],
    [IMAGE_KEYS.IMAGE5]: listImage[4],
  });

  const [keyImage, setKeyImage] = useState({
    [IMAGE_KEYS.IMAGE1]: listKeyImage[0],
    [IMAGE_KEYS.IMAGE2]: listKeyImage[1],
    [IMAGE_KEYS.IMAGE3]: listKeyImage[2],
    [IMAGE_KEYS.IMAGE4]: listKeyImage[3],
    [IMAGE_KEYS.IMAGE5]: listKeyImage[4],
  });

  const [uploadFile, setUploadFile] = useState({});

  const arrayFromObject = (object) => {
    return Object.values(object).filter((item) => !!item);
  };

  const handleUploadImage = (key) => async (e) => {
    if (hasCertificate) {
      return;
    }

    const file = e?.target?.files[0];
    const compressedFile = await compressImage(file);
    const uri = URL.createObjectURL(compressedFile);
    const imageKey = generateImageKey(file);
    const data = {
      uri,
      uploadFile: compressedFile,
      imageKey,
    };

    const newDisplayImage = { ...displayImage, [key]: uri };
    const newKeyImage = { ...keyImage, [key]: imageKey };
    const newUploadFile = { ...uploadFile, [key]: data };

    setDisplayImage(newDisplayImage);
    setKeyImage(newKeyImage);
    setUploadFile(newUploadFile);

    // Update Form Value
    setValue('images', arrayFromObject(newDisplayImage), { shouldValidate: true });
    setValue('keyImages', arrayFromObject(newKeyImage));
    setValue('uploadFiles', arrayFromObject(newUploadFile));
  };

  const handleRemoveImage = (key) => () => {
    if (hasCertificate) {
      return;
    }

    const newDisplayImage = { ...displayImage, [key]: null };
    const newKeyImage = { ...keyImage, [key]: null };
    const newUploadFile = { ...uploadFile, [key]: null };

    setDisplayImage(newDisplayImage);
    setKeyImage(newKeyImage);
    setUploadFile(newUploadFile);

    // Update Form Value
    setValue('images', arrayFromObject(newDisplayImage), { shouldValidate: true });
    setValue('keyImages', arrayFromObject(newKeyImage));
    setValue('uploadFiles', arrayFromObject(newUploadFile));
  };

  return (
    <EditArtworkSection
      sx={{ mt: 2 }}
      heading={t('artwork_images')}
    >
      <Stack
        sx={{ mt: 3 }}
        direction={{ lg: 'row', xs: 'column' }}
        spacing={2}
      >
        <ArtworkUploadImage
          image={displayImage[IMAGE_KEYS.IMAGE1]}
          onUploadImage={handleUploadImage(IMAGE_KEYS.IMAGE1)}
          onRemoveImage={handleRemoveImage(IMAGE_KEYS.IMAGE1)}
          isRequired
          alt="image front"
          removeIconTestId={`${TESTID.ICON_REMOVE_IMAGE}_IMAGE_FRONT`}
          textRecommend={t('front')}
          wrapStyle={hasCertificate && classes.notAllow}
        />
        <ArtworkUploadImage
          image={displayImage[IMAGE_KEYS.IMAGE2]}
          onUploadImage={handleUploadImage(IMAGE_KEYS.IMAGE2)}
          onRemoveImage={handleRemoveImage(IMAGE_KEYS.IMAGE2)}
          alt="image back"
          textRecommend={t('back_side')}
          wrapStyle={hasCertificate && classes.notAllow}
        />
        <ArtworkUploadImage
          image={displayImage[IMAGE_KEYS.IMAGE3]}
          onUploadImage={handleUploadImage(IMAGE_KEYS.IMAGE3)}
          onRemoveImage={handleRemoveImage(IMAGE_KEYS.IMAGE3)}
          alt="image detail"
          textRecommend={t('detail')}
          uploadImageTestId={`${TESTID.INPUT_UPLOAD_IMAGE}_IMAGE_DETAIL`}
          wrapStyle={hasCertificate && classes.notAllow}
        />
        <ArtworkUploadImage
          image={displayImage[IMAGE_KEYS.IMAGE4]}
          onUploadImage={handleUploadImage(IMAGE_KEYS.IMAGE4)}
          onRemoveImage={handleRemoveImage(IMAGE_KEYS.IMAGE4)}
          alt="image signature"
          textRecommend={t('signature')}
          wrapStyle={hasCertificate && classes.notAllow}
        />
        <ArtworkUploadImage
          image={displayImage[IMAGE_KEYS.IMAGE5]}
          onUploadImage={handleUploadImage(IMAGE_KEYS.IMAGE5)}
          onRemoveImage={handleRemoveImage(IMAGE_KEYS.IMAGE5)}
          alt="image damage"
          textRecommend={t('damage')}
          wrapStyle={hasCertificate && classes.notAllow}
        />
      </Stack>

      <FormLabel
        error={!!formState?.errors.images}
        sx={{ mt: 1, display: 'block' }}
        ref={register('images').ref}
        tabIndex={0}
      >
        {formState?.errors.images?.message}
      </FormLabel>
    </EditArtworkSection>
  );
};

export default EditArtworkImages;
