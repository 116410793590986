import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import API from 'configs/API';

import EditArtworkSection from '../EditArtworkSection';
import EditArtworkEditionHead from './EditArtworkEditionHead';
import EditArtworkEditionItem from './EditArtworkEditionItem';

const EditArtworkEdition = ({ artworkFormState }) => {
  const { t } = useTranslation();

  const { control, trigger } = artworkFormState;
  const { fields } = useFieldArray({
    control,
    name: 'editions',
    keyName: 'customId',
  });

  const { data: statusOptions, isPending } = useQuery({
    queryKey: [API.ARTWORK.STATUS],
  });
  const { data: locationOptions, isPending: isLocationPending } = useQuery({
    queryKey: [API.AUTH.LOCATION],
  });

  const handleEditionStatusChange = () => {
    trigger();
  };

  if (isPending || isLocationPending) {
    return;
  }

  if (fields?.length <= 0) {
    return;
  }

  return (
    <EditArtworkSection heading={t('status_and_location')}>
      <EditArtworkEditionHead />

      {fields.map((edition, index) => (
        <EditArtworkEditionItem
          key={edition.customId}
          edition={edition}
          index={index}
          control={control}
          statusOptions={statusOptions}
          locationOptions={locationOptions}
          onStatusChange={handleEditionStatusChange}
        />
      ))}
    </EditArtworkSection>
  );
};

export default EditArtworkEdition;
