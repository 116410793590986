import { Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TabPanel from 'commons/TabPanel';
import { ARTWORK_FILTER_PARAMS } from 'configs/FilterArtwork';
import useRole from 'hooks/useRole';

import FilterArtist from './FilterType/FilterArtist';
import FilterCategory from './FilterType/FilterCategory';
import FilterCreationYear from './FilterType/FilterCreationYear';
import FilterMedium from './FilterType/FilterMedium';
import FilterOrientation from './FilterType/FilterOrientation';
import FilterPrice from './FilterType/FilterPrice';
import FilterStyle from './FilterType/FilterStyle';
import FilterSubject from './FilterType/FilterSubject';
import FilterWrapper from './FilterWrapper';
import useStyles from './styles';

const FilterArtwork = () => {
  const [tabValue, setTabValue] = useState(1);

  const { isCollector } = useRole();

  const classes = useStyles();
  const { t } = useTranslation();

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        <Tab
          label={t('category')}
          value={1}
          className={classes.tab}
        />
        <Tab
          label={t('style')}
          value={2}
          className={classes.tab}
        />
        <Tab
          label={t('subject')}
          value={3}
          className={classes.tab}
        />
        <Tab
          label={t('medium')}
          value={4}
          className={classes.tab}
        />
        <Tab
          label={t('creation_year')}
          value={5}
          className={classes.tab}
        />
        <Tab
          label={t('price')}
          value={6}
          className={classes.tab}
        />
        {isCollector && (
          <Tab
            label={t('cost_price')}
            value={7}
            className={classes.tab}
          />
        )}

        <Tab
          label={t('orientation')}
          value={8}
          className={classes.tab}
        />

        {isCollector && (
          <Tab
            label={t('artist')}
            value={9}
            className={classes.tab}
          />
        )}
      </Tabs>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <FilterWrapper filterType={<FilterCategory />} />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={2}
      >
        <FilterWrapper filterType={<FilterStyle />} />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={3}
      >
        <FilterWrapper filterType={<FilterSubject />} />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={4}
      >
        <FilterWrapper filterType={<FilterMedium />} />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={5}
      >
        <FilterWrapper filterType={<FilterCreationYear />} />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={6}
      >
        <FilterWrapper filterType={<FilterPrice priceParam={ARTWORK_FILTER_PARAMS.PRICE} />} />
      </TabPanel>

      {isCollector && (
        <TabPanel
          value={tabValue}
          index={7}
        >
          <FilterWrapper
            filterType={<FilterPrice priceParam={ARTWORK_FILTER_PARAMS.COST_PRICE} />}
          />
        </TabPanel>
      )}

      <TabPanel
        value={tabValue}
        index={8}
      >
        <FilterWrapper filterType={<FilterOrientation />} />
      </TabPanel>

      {isCollector && (
        <TabPanel
          value={tabValue}
          index={9}
        >
          <FilterWrapper filterType={<FilterArtist />} />
        </TabPanel>
      )}
    </>
  );
};

export default FilterArtwork;
