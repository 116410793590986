import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Text from 'commons/Text';
import { ARTWORK_FILTER_PARAMS } from 'configs/FilterArtwork';
import useRole from 'hooks/useRole';

import ArtistTag from './ArtistTag';
import CategoryTag from './CategoryTag';
import CreationYearTag from './CreationYearTag';
import MediumTag from './MediumTag';
import OrientationTag from './OrientationTag';
import PriceTag from './PriceTag';
import StyleTag from './StyleTag';
import SubjectTag from './SubjectTag';
import useStyles from './styles';

const FilterTags = ({ wrapFilterStyle }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isCollector } = useRole();

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClearFilter = () => {
    const tab = searchParams.get('tab');

    const newSearchParams = new URLSearchParams();
    newSearchParams.set('tab', tab);
    setSearchParams(newSearchParams);
  };

  return (
    <div className={clsx(classes.wrapFilterSelect, wrapFilterStyle)}>
      <Text type="sm">{t('filtered')}:</Text>

      <div className={classes.wrapTag}>
        <CategoryTag />
        <StyleTag />
        <SubjectTag />
        <MediumTag />
        <PriceTag priceParam={ARTWORK_FILTER_PARAMS.PRICE} />
        {isCollector && <PriceTag priceParam={ARTWORK_FILTER_PARAMS.COST_PRICE} />}
        <OrientationTag />
        <CreationYearTag />
        {isCollector && <ArtistTag />}

        <Text
          type="sm"
          clickAble
          onClick={handleClearFilter}
        >
          {t('clear_all')}
        </Text>
      </div>
    </div>
  );
};

export default FilterTags;
