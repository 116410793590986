import { Button, Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Text from 'commons/Text';
import { ARTWORK_FILTER_PARAMS, ORDER_TYPE } from 'configs/FilterArtwork';
import { ReactComponent as FilterSort } from 'images/filter_sort.svg';

import useStyles from './styles';

const { ORDERING } = ARTWORK_FILTER_PARAMS;
const SortArtwork = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSort, setShowSort] = useState(false);

  const open = Boolean(anchorEl);

  const handleShowSort = (e) => {
    setAnchorEl(e.currentTarget);
    setShowSort(!showSort);
  };

  const handleCloseSort = () => {
    setAnchorEl(null);
    setShowSort(false);
  };

  const handleSortPriceAsc = () => {
    searchParams.set(ORDERING, ORDER_TYPE.PRICE_ASC);
    searchParams.delete('page');
    setSearchParams(searchParams);
    handleCloseSort();
  };

  const handleSortPriceDesc = () => {
    searchParams.set(ORDERING, ORDER_TYPE.PRICE_DESC);
    searchParams.delete('page');
    setSearchParams(searchParams);
    handleCloseSort();
  };

  const handleSortYearAsc = () => {
    searchParams.set(ORDERING, ORDER_TYPE.YEAR_ASC);
    searchParams.delete('page');
    setSearchParams(searchParams);
    handleCloseSort();
  };

  const handleSortYearDesc = () => {
    searchParams.set(ORDERING, ORDER_TYPE.YEAR_DESC);
    searchParams.delete('page');
    setSearchParams(searchParams);
    handleCloseSort();
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleShowSort}
        className={clsx(classes.btnFilter, {
          [classes.btnFilterActive]: showSort,
        })}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <FilterSort />
        {t('sort')}
      </Button>

      <Menu
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={open}
        onClose={handleCloseSort}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={classes.menu}
      >
        <MenuItem
          onClick={handleSortPriceAsc}
          className={classes.menuItem}
        >
          <Text type="sm">{t('price_low_to_high')}</Text>
        </MenuItem>
        <MenuItem
          onClick={handleSortPriceDesc}
          className={classes.menuItem}
        >
          <Text type="sm">{t('price_high_to_low')}</Text>
        </MenuItem>
        <MenuItem
          onClick={handleSortYearAsc}
          className={classes.menuItem}
        >
          <Text type="sm">{t('date_old_to_new')}</Text>
        </MenuItem>
        <MenuItem
          onClick={handleSortYearDesc}
          className={classes.menuItem}
        >
          <Text type="sm">{t('date_new_to_old')}</Text>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SortArtwork;
