import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import API from 'configs/API';
import { axiosGet } from 'utils/apis/axios';

import ArtistAbout from './components/ArtistAbout';
import ArtistStatements from './components/ArtistStatements';
import Awards from './components/Awards';
import Biography from './components/Biography';
import Exhibitions from './components/Exhibitions';
import Publications from './components/Publications';
import useStyles from './styles';

const ArtistProfileColRight = ({ userProfile }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();

  const [listAward, setListAward] = useState([]);
  const [listSoloExhibition, setListSoloExhibition] = useState([]);
  const [listGroupExhibition, setListGroupExhibition] = useState([]);
  const [listPublication, setListPublication] = useState([]);

  const fetchData = async (apiEndpoint, stateUpdater) => {
    const { success, data } = await axiosGet(apiEndpoint, {
      params: {
        is_public: true,
        user_id: id ? id : null,
      },
    });
    if (success) {
      stateUpdater(data);
    }
  };

  useEffect(() => {
    Promise.all([
      fetchData(API.AUTH.AWARD.LIST, setListAward),
      fetchData(API.AUTH.SOLO_EXHIBITION.LIST, setListSoloExhibition),
      fetchData(API.AUTH.GROUP_EXHIBITION.LIST, setListGroupExhibition),
      fetchData(API.AUTH.PUBLICATION.LIST, setListPublication),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapColRight}>
      <ArtistStatements statement={userProfile?.introduction} />

      <ArtistAbout aboutArtist={userProfile?.about_artist} />

      <Biography userProfile={userProfile} />

      <Awards listAward={listAward} />

      <Exhibitions
        listExhibition={listSoloExhibition}
        textExhibition={t('solo_exhibitions')}
      />
      <Exhibitions
        listExhibition={listGroupExhibition}
        textExhibition={t('group_exhibitions')}
      />

      <Publications listPublication={listPublication} />
    </div>
  );
};

export default ArtistProfileColRight;
