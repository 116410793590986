import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import Visibility from 'images/logo-visibility.svg';
import VisibilityOff from 'images/logo-visibilityOff.svg';

import useStyles from './styles';

const FormInputPassword = ({
  label,
  value,
  onChange,
  onKeyDown,
  placeholder,
  error,
  helperText,
  isRequired,
  wrapFormInputStyle,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={clsx(classes.wrapFormInput, wrapFormInputStyle)}>
      <Text
        className={classes.inputLabel}
        type="sm"
      >
        {label}
        {isRequired && <span className={classes.require}>*</span>}
      </Text>

      <FormControl
        fullWidth
        variant="outlined"
        className={classes.textField}
        error={error}
      >
        <OutlinedInput
          value={value || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
          testid={TESTID.INPUT_PASSWORD}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                testid={TESTID.ICON_BUTTON_SHOW_PASSWORD}
              >
                {showPassword ? (
                  <img
                    className={classes.iconEye}
                    src={VisibilityOff}
                  />
                ) : (
                  <img
                    className={classes.iconEye}
                    src={Visibility}
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      {error && (
        <FormHelperText
          className={classes.helperText}
          error
        >
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

export default FormInputPassword;
